<template>
  <div class="content">
    <TitleInfo title1="组态管理" title2="系统背景"></TitleInfo>
    <div class="main">
      <el-upload
        list-type="picture-card"
        :file-list="ImgUrlList"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :before-upload="checkFile"
        :action="uploadUrl"
        :accept="fileTypes"
        :headers="header"
      >
        <el-icon><Plus /></el-icon>
      </el-upload>
    </div>
    <Dialog
      v-model="dialogVisible"
      :imgUrl="dialogImageUrl"
      v-if="dialogVisible"
    ></Dialog>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import { GetBgImgList, DeleteBgImg } from '@/api/SCADABgImg'
import Dialog from './components/dialog.vue'
import TitleInfo from '@/components/TitleInfo'
import store from '@/store'
const ImgUrlList = ref([])
const fileTypes = ref('.jpeg,.png,.jpg,.bmp,.gif')
const header = ref({})
const initGetList = async () => {
  ImgUrlList.value.length = 0
  GetBgImgList()
    .then((res) => {
      if (res) {
        res.Data.forEach((element) => {
          ImgUrlList.value.push({
            name: element.ID,
            url: element.Content
          })
        })
      } else {
        ImgUrlList.value.length = 0
      }
    })
    .catch((err) => {
      ImgUrlList.value.length = 0
    })
}
onMounted(() => {
  initGetList()
  header.value.Authorization = store.state.login.token
})
let uploadUrl =
  process.env.NODE_ENV === 'development'
    ? '/api/SCADABgImg/UploadBgImg'
    : window.global_config.BASE_URL + 'api/SCADABgImg/UploadBgImg'
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const handleRemove = (uploadFile, uploadFiles) => {
  let id = uploadFile.name
  if (typeof uploadFile.response != 'undefined') {
    id = uploadFile.response.Data
  }
  DeleteBgImg({ id: id })
}
const handlePictureCardPreview = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url
  dialogVisible.value = true
}
const checkFile = (file) => {
  let fileName = file.name
  const fileType = fileName.substring(fileName.lastIndexOf('.'))
  if (fileTypes.value.split(',').indexOf(fileType) < 0) {
    ElMessage.error('请上传正确的图片类型!')
    return false
  }
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
