<template>
  <el-dialog
    :model-value="dialogVisible"
    title="背景图预览"
    @opened="handleOpen"
    @close="handleClose"
    draggable
  >
    <el-image style="width: 100%" :src="url" fit="contain" />
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  imgUrl: {
    type: String,
    default: '',
    required: true
  }
})
const dialogVisible = ref(false)
const url = ref('')
const emits = defineEmits(['update:modelValue'])
const handleOpen = () => {
  url.value = props.imgUrl
}
const handleClose = () => {
  emits('update:modelValue', false)
}
</script>
