import $http from '@/utitls/request'
/**
 * 获取背景图信息
 */
export const GetBgImgList = () => {
    return $http.post('/api/SCADABgImg/GetBgImgList')
}
/**
 * 新增
 */
export const UploadBgImg = () => {
    return $http.post('/api/SCADABgImg/UploadBgImg')
}
/**
 * 删除
 */
export const DeleteBgImg = (data) => {
    return $http.delete('/api/SCADABgImg/DeleteBgImg', { params: data })
}